// ["000b42","d7263d","ff5d53","2d728f","f7f7ff"]
@import 'mqs.scss';

$background-color: #fffffc; //F7F7FF;
$default-shape-color: #eee;
$type-color: #000B42;
$accent-color: #FF5D53;

$font-size-S: 1rem;
$font-size-XM: 1.5rem;
$font-size-M: 2rem;
$font-size-L: 2.2rem;
$font-size-XL: 3rem;


$max-headline-width: 45ch;
$max-line-width: 30ch; // x2 to create a full column


$gap: 4%;
$gap-breather-S: 5%;
$gap-breather-M: 15%;

//$column-text-width: 32ch;
$column-text-width--A: 20%;
$column-text-width--B: 20%;

$gutter: 2ch;
$half-gutter: ceil($gutter * 0.5);
$double-gutter: ceil($gutter * 2);
$large-gutter: ceil($gutter * 6);

$shapes-radius: 3ch;
$shapes-inner-breather: 6em;
$skew-value: 4deg;

// THEMES
// Connect
$color-connect: #127996;
$type-connect: #fff;
// Assistant
$color-assistant: #E0F3FD;
$type-assistant: #303EA6;
// Renault
$color-renault: #FFCE00;
$type-renault: #000;
// Poscat
$color-poscat: #ffd861;
$type-poscat: #333;



// thumbnails size reduction
$scaled-down-by: 0.75;
$scaled-down-by-XS: 0.4;

@mixin typeset-smaller {
    font-family: sans-serif;
    font-weight: 400;
    font-size: 1rem;
}

@mixin typeset-default {
    font-family: "Gilroy Light", "Avenir", Arial, sans-serif;
    font-weight: 300;
}

@mixin typeset-emphasis {
    font-family: "Gilroy RegularItalic", "Avenir", Arial, sans-serif;
    font-style: italic;
    font-weight: 400;
}

@mixin typeset-title {
    font-family: "Gilroy SemiBold", "Avenir Medium", Arial, sans-serif;
    font-size: $font-size-L;
    line-height: 1.1em;
    font-weight: 500;
}

@mixin typeset-smallcaps {
    font-family: "Gilroy SemiBold", "Avenir", Arial, sans-serif;
    font-size: $font-size-S;
    line-height: 1rem;
    font-weight: 500;
    text-transform: uppercase;
}

@mixin typeset-heavy {
    font-family: "Gilroy Heavy", "Avenir Black", Arial, sans-serif;
    font-size: $font-size-XL;
    line-height: 1.1em;
    font-weight: 700;
}


@mixin typeset-headline {
    font-family: "Argesta Display Regular", serif;
    font-size: $font-size-M;
    line-height: $font-size-M;
    font-weight: 300;
}

@mixin grid-rows {
    display: grid;
    grid-template-rows: auto;
    grid-column-gap: $gap;
}

@mixin time-capsule {
    align-self: start;
    @include typeset-smallcaps;
    padding: $half-gutter;
    margin-bottom: $gutter;
    border-radius: 1em;
}

:root {
    @include typeset-default();
    font-weight: 300;
    font-size: 16px;
    padding: $large-gutter 0;
    color: $type-color;
    background-color: $background-color;
    --pr-background-color: #{$default-shape-color};
    --pr-typeset-color: inherit;
}

body {
    @include grid-rows;
    grid-template-columns: [borne-gauche] $gap-breather-S [borne-tiers-gauche] $gap-breather-M [borne-principale] $column-text-width--A [borne-centrale] $column-text-width--B [borne-contenu-fin] $gap-breather-M [borne-tiers-droite] $gap-breather-S [borne-finale];
    grid-template-areas:
        ". . masthead masthead . ."
        "portfolio portfolio portfolio portfolio portfolio portfolio"
        ". publications publications publications publications ."
        "footer footer footer footer footer footer";
    padding: 1em 2em;
}

.masthead,
#publications {
    @include grid-rows;
}

.masthead {
    grid-template-columns: [mast-A] 1fr [mast-C] 1fr [mast-D];
    grid-template-areas:
        "about about"
        "navigation navigation"
}

#publications {
    grid-template-columns: [pub-A] $gap-breather-S [pub-B] 1fr [pub-C] 1fr [pub-D] $gap-breather-S [pub-E];
    grid-area: publications;
    grid-template-areas:
        ". books talks ."
}

.portfolio {
    margin-top: $large-gutter;
}

header {
    grid-area: masthead;
    justify-items: start;
}

main {
    grid-area: portfolio;
    justify-items: start;
}

aside[role="contentinfo"] {
    margin-top: $large-gutter;
    grid-area: footer;
}

h1,
h2 {
    //@include typeset-title; // -heavy
    //font-weight: 300;
    font-size: $font-size-XL;
    margin-top: 0;
}

h2 {
    font-size: 3em;
}

mark {
    color: $accent-color;

    font-weight: 900;
    font-family: "Gilroy Heavy", "Avenir Black", Arial, sans-serif;

}

i {
    font-family: serif;
    font-weight: 300;
    font-style: italic;
}

.masthead {
    display: grid;

    &-about,
    &-grapheme {
        position: relative;
    }

    &-grapheme {
        grid-area: about;
        overflow-x: visible;
        transform: translateX(-65%);
        z-index: 1;
        position: absolute;
    }

    &-about {
        grid-area: about;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        z-index: 2;

        &-statement {
            &+& {
                margin-top: 0;
            }
        }
    }

    &-nav {
        grid-area: navigation;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: 100%;
    }
}

.typeset-xl {
    @include typeset-title();
}

.typeset-m {
    @include typeset-headline();
}

#grapheme {
    width: 360px;
    height: 410px;
}

.greetings,
.chapeau {
    font-size: $font-size-L;
}

.chapeau {
    font-family: "Argesta", serif;
}


mark {
    background-color: transparent;
    text-transform: capitalize;
}

/*nav {
}*/

.nav-list {
    list-style: none outside;
    padding: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;

    &+& {
        //padding-left: $gutter;
        margin-left: $double-gutter;
    }

    &-link {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: $type-color;
        cursor: pointer;
    }
}

.as-social-networks {
    margin-left: $double-gutter + $gutter;
}

.with-button::after {
    content: url('../assets/icon-plus.svg');
    margin-left: $half-gutter;
}

.icon-social {
    width: 2.2em;
}

.is-mastodon {
    transform: translateY(1px);
}

ol {
    margin: 0;
}

li.nav-list-item {
    @include typeset-smallcaps();

    margin-left: $half-gutter;
    margin-right: $half-gutter;

    /*&:first-child {
        margin-left: 0;
    }

    &:last-child {
        margin-right: 0;
    }*/
}

ul {
    padding-left: 0;
}

aside {
    display: block;
    font-size: $font-size-XM;
    margin: $gutter $gap-breather-S $double-gutter $gap-breather-S;
    //padding: 0 #{$gap-breather-S + $gap-breather-M};
    padding: $double-gutter $gap-breather-M 0 $gap-breather-M;
    border-top: 1px solid black;
}

folio-experience {
    &::part(container) {
        @include grid-rows;
        grid-template-columns: [pr-gauche] $gap-breather-S [pr-tiers-gauche] $gap-breather-M [pr-principale] $column-text-width--A [pr-centrale] $column-text-width--B [pr-contenu-fin] $gap-breather-M [pr-tiers-droit] $gap-breather-S [pr-finale];
        grid-template-areas:
            ". longevity . . . ."
            ". descript descript thumbnails . .";
        transform: skew(0, -$skew-value);
        background-color: var(--pr-background-color);
        padding: $shapes-inner-breather 0 $shapes-inner-breather 0;
        margin-bottom: $large-gutter;
        border-radius: $shapes-radius;
        overflow: hidden;
    }

    &::part(contents) {
        grid-area: descript;
        display: flex;
        flex-direction: column;
        color: var(--pr-typeset-color);
        font-size: $font-size-XM;
    }

    &::part(preview) {
        grid-area: thumbnails;
        display: flex;
        flex-direction: row;
        align-items: center;
    }

    &::part(contents),
    &::part(preview) {
        transform: skew(0, $skew-value);
    }

    &::part(timespan) {
        @include time-capsule();
        background-color: var(--pr-typeset-color);
        color: var(--pr-background-color);
    }
}

.phone,
.desktop,
.desktopB {
    box-shadow: 0px $half-gutter $gutter 0px rgba(0, 0, 0, 0.5);
}

.thumbnail {
    border-radius: 16px; // to match PNGs


    &.phone {
        width: 380px * $scaled-down-by;
        height: 700px * $scaled-down-by;
    }

    &.desktop {
        width: 1472px * $scaled-down-by-XS;
        height: 1056px * $scaled-down-by-XS;
    }

    &.desktopB {
        width: 1480px * $scaled-down-by-XS;
        height: 1064px * $scaled-down-by-XS;
    }

    &.phone.rad32 {
        border-radius: 32px;
    }


    &.layer-A {
        z-index: 5;
    }

    &.layer-B {
        z-index: 4;
    }

    &.layer-C {
        z-index: 3;
    }

    &.layer-D {
        z-index: 2;
    }

    &.layer-recess {
        z-index: -1;
    }
}

.tilt {
    &-left-S {
        transform: rotate(-5deg);
    }

    &-right-S {
        transform: rotate(5deg);
    }

    &-left-XS {
        transform: rotate(-2deg);
    }

    &-right-XS {
        transform: rotate(2deg);
    }
}

.shift {
    &-left-S {
        transform: translateX(-$double-gutter);
    }

    &-right-S {
        transform: translateX(-$double-gutter);
    }

    &-left-M {
        transform: translateX(-$large-gutter);
    }

    &-right-M {
        transform: translateX(-$large-gutter);
    }
}

.shift-left-and-tilt {
    transform: translateX(-$gutter - $double-gutter) rotate(-5deg);
}

time[slot="timespan-from"] {
    margin-right: $half-gutter;
}

time[slot="timespan-to"] {
    margin-left: $half-gutter;
}


.intro {
    font-size: $font-size-XM;
}

li.intro {
    line-height: $font-size-L;
}

h3.intro {
    margin-top: 0;
}

.strong-kpi,
cite {
    font-weight: 900;
    font-style: normal;
    font-family: "Gilroy Heavy", "Avenir Black", Arial, sans-serif;
}

.release {
    display: inline-block;
    @include time-capsule();
    background-color: var(--pr-background-color);
    color: var(--pr-typeset-color);
}

.pub-talks .release {
    margin: 0;
}

.pub-talks-peryear {
    flex-direction: column;

    &+& {
        margin-top: $gutter;
    }
}

.is-sncf-connect {
    --pr-background-color: #{$color-connect};
    --pr-typeset-color: #{$type-connect};
}

.is-assistant-sncf {
    --pr-background-color: #{$color-assistant};
    --pr-typeset-color: #{$type-assistant};
}

.is-renault {
    --pr-background-color: #{$color-renault};
    --pr-typeset-color: #{$type-renault};
}

.is-poscat {
    --pr-background-color: #{$color-poscat};
    --pr-typeset-color: #{$type-poscat};
}

.is-mecaniques {
    --pr-background-color: #DF1F36;
    --pr-typeset-color: #fff;
}


.pub {
    &-books {
        grid-area: books;
    }

    &-talks {
        grid-area: talks;
    }
}

.as-flex {
    display: flex;
    align-items: flex-start;

    &.book,
    &.pub {
        flex-direction: column;
    }
}

.is-book {
    height: 300px;
}

.is-talk {
    display: inline-block;
    margin-bottom: $gutter;
    @include typeset-smaller();
}


/*
MQ land starts here
*/

$gap-mobile: 5ch;

@include MEDIA-XL-and-above {
    .as-social-networks {
        margin-left: $large-gutter;
    }

    .masthead-about-statement {
        max-width: 50ch;
    }

    .masthead-nav {
        margin-top: $double-gutter;
        margin-bottom: $large-gutter;
    }
}

@include MEDIA-XL-and-below {
    body {
        grid-template-areas:
            ". masthead masthead masthead masthead ."
            "portfolio portfolio portfolio portfolio portfolio portfolio"
            ". publications publications publications publications ."
            "footer footer footer footer footer footer";
    }

    .masthead-nav {
        flex-wrap: wrap;
        justify-content: center;
    }

    .as-social-networks {
        margin-left: 0;
    }

    .is-nav {
        margin-top: $gutter;
    }
}

@include MEDIA-L-and-above {
    li.nav-list-item {
        &+& {
            margin-left: $double-gutter;
        }
    }
}

@include MEDIA-L-and-below {
    :root {
        padding-top: $large-gutter;
        font-size: 11px;
    }

    body,
    .masthead,
    #publications {
        grid-column-gap: $gap-mobile;
    }

    body {
        grid-template-columns: [A] $gap-mobile [B] 1fr [C] 2fr [D] 1fr [E] $gap-mobile [F];
        grid-template-areas:
            ". masthead masthead masthead ."
            "portfolio portfolio portfolio portfolio portfolio"
            "publications publications publications publications publications";
        padding: 0;
        margin: 0;
    }

    #publications {
        grid-area: publications;
        grid-template-columns: [A] $gap-mobile [B] 1fr [C] 1fr [D] $gap-mobile [F];
        grid-template-areas:
            ". books books ."
            ". talks talks ."
    }

    .masthead {
        grid-template-columns: 1fr;
        grid-template-areas:
            "about"
            "navigation";

        &-grapheme {
            transform: translate(-35%, -65%) scale(.8);
        }

        &-nav {
            flex-direction: column;
            gap: $gutter;
        }
    }

    body,
    .masthead,
    folio-experience::part(container) {
        grid-column-gap: 0; //$gap-mobile;
    }

    .nav-list {
        flex-wrap: wrap;
        gap: $double-gutter;
    }

    .as-social-networks {
        margin-left: 0;
    }

    .nav-list-link {
        padding: $half-gutter;
    }

    .icon-social {
        width: 2.6em;
    }

    .masthead-about-statement:last-child {
        margin-bottom: $double-gutter;
    }

    .is-nav+.is-nav {
        margin-top: $gutter;
    }

    .portfolio {
        margin-top: $double-gutter;
    }

    folio-experience {

        &::part(container) {
            grid-template-columns: [A] $gap-mobile [B] 1fr [C] 2fr [D] 1fr [E] $gap-mobile [F];
            /*grid-template-areas:
            ". masthead masthead masthead ."
            "portfolio portfolio portfolio portfolio portfolio";*/
            grid-template-areas:
                ". longevity . . ."
                ". descript descript descript ."
                ". thumbnails thumbnails thumbnails thumbnails";
            padding: $large-gutter 0;
        }

        &::part(contents) {
            margin-bottom: $double-gutter;
        }
    }

    .list-kpi-item {
        margin-bottom: $half-gutter;
    }

    .thumbnail.phone {
        width: 285px * 0.75;
        height: 525px * 0.75;
    }

    strong.strong-kpi {
        font-weight: 500;
        font-family: "Gilroy SemiBold", "Avenir Medium", Arial, sans-serif;
    }

    .pub-talks {
        margin-top: $double-gutter;
    }

}