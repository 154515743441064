$mq-size-XXL: 1900px;
$mq-size-XL: 1200px;
$mq-size-L: 800px;
$mq-size-M: 600px;
$mq-size-S: 320px;

$mq-size-L--height: 690px;

@mixin MEDIA-generic($modifier: '') {
	@if $modifier !='' {
		@media screen and #{$modifier} {
			@content;
		}
	}

	@else {
		@media screen {
			@content;
		}
	}
}

@mixin MEDIA-is-touch-only {
	@media (hover: none) {
		@content;
	}
}

@mixin MEDIA-has-mouse {
	@media (hover: hover) {
		@content;
	}
}

@mixin MEDIA-mobile-breakpoint {
	@media screen and (width <=$mq-size-L) and (orientation: portrait) {
		@content;
	}

	@media screen and (min-aspect-ratio: 3/2) and (orientation: landscape) {
		@content;
	}
}

@mixin MEDIA-XXL-and-above {
	@media (width > $mq-size-XXL) {
		@content;
	}
}

@mixin MEDIA-XL-and-above {
	@media (width > $mq-size-XL) {
		@content;
	}
}

@mixin MEDIA-XL-and-below {
	@media (width <=$mq-size-XL ) {
		@content;
	}
}

@mixin MEDIA-L-and-above {
	@media (width > $mq-size-L ) {
		@content;
	}
}

@mixin MEDIA-L-and-below {
	@media (width <=$mq-size-L ) {
		@content;
	}
}