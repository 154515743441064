:root {
  color: #000b42;
  --pr-background-color: #eee;
  --pr-typeset-color: inherit;
  background-color: #fffffc;
  padding: 12ch 0;
  font-family: Gilroy Light, Avenir, Arial, sans-serif;
  font-size: 16px;
  font-weight: 300;
}

body {
  grid-column-gap: 4%;
  grid-template: ". . masthead masthead . ."
                 "portfolio portfolio portfolio portfolio portfolio portfolio"
                 ". publications publications publications publications ."
                 "footer footer footer footer footer footer"
                 / [borne-gauche] 5%[borne-tiers-gauche] 15%[borne-principale] 20%[borne-centrale] 20%[borne-contenu-fin] 15%[borne-tiers-droite] 5%[borne-finale];
  padding: 1em 2em;
  display: grid;
}

.masthead, #publications {
  grid-column-gap: 4%;
  grid-template-rows: auto;
  display: grid;
}

.masthead {
  grid-template-columns: [mast-A] 1fr[mast-C] 1fr[mast-D];
  grid-template-areas: "about about"
                       "navigation navigation";
}

#publications {
  grid-area: publications;
  grid-template-columns: [pub-A] 5%[pub-B] 1fr[pub-C] 1fr[pub-D] 5%[pub-E];
  grid-template-areas: ". books talks .";
}

.portfolio {
  margin-top: 12ch;
}

header {
  grid-area: masthead;
  justify-items: start;
}

main {
  grid-area: portfolio;
  justify-items: start;
}

aside[role="contentinfo"] {
  grid-area: footer;
  margin-top: 12ch;
}

h1, h2 {
  margin-top: 0;
  font-size: 3rem;
}

h2 {
  font-size: 3em;
}

mark {
  color: #ff5d53;
  font-family: Gilroy Heavy, Avenir Black, Arial, sans-serif;
  font-weight: 900;
}

i {
  font-family: serif;
  font-style: italic;
  font-weight: 300;
}

.masthead {
  display: grid;
}

.masthead-about, .masthead-grapheme {
  position: relative;
}

.masthead-grapheme {
  z-index: 1;
  grid-area: about;
  position: absolute;
  overflow-x: visible;
  transform: translateX(-65%);
}

.masthead-about {
  z-index: 2;
  flex-direction: column;
  grid-area: about;
  justify-content: flex-end;
  display: flex;
}

.masthead-about-statement + .masthead-about-statement {
  margin-top: 0;
}

.masthead-nav {
  flex-direction: row;
  grid-area: navigation;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  display: flex;
}

.typeset-xl {
  font-family: Gilroy SemiBold, Avenir Medium, Arial, sans-serif;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 1.1em;
}

.typeset-m {
  font-family: Argesta Display Regular, serif;
  font-size: 2rem;
  font-weight: 300;
  line-height: 2rem;
}

#grapheme {
  width: 360px;
  height: 410px;
}

.greetings, .chapeau {
  font-size: 2.2rem;
}

.chapeau {
  font-family: Argesta, serif;
}

mark {
  text-transform: capitalize;
  background-color: #0000;
}

.nav-list {
  flex-direction: row;
  justify-content: center;
  padding: 0;
  list-style: none;
  display: flex;
}

.nav-list + .nav-list {
  margin-left: 4ch;
}

.nav-list-link {
  color: #000b42;
  cursor: pointer;
  align-items: center;
  text-decoration: none;
  display: flex;
}

.as-social-networks {
  margin-left: 6ch;
}

.with-button:after {
  content: url("icon-plus.95ae2653.svg");
  margin-left: 1ch;
}

.icon-social {
  width: 2.2em;
}

.is-mastodon {
  transform: translateY(1px);
}

ol {
  margin: 0;
}

li.nav-list-item {
  text-transform: uppercase;
  margin-left: 1ch;
  margin-right: 1ch;
  font-family: Gilroy SemiBold, Avenir, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
}

ul {
  padding-left: 0;
}

aside {
  border-top: 1px solid #000;
  margin: 2ch 5% 4ch;
  padding: 4ch 15% 0;
  font-size: 1.5rem;
  display: block;
}

folio-experience::part(container) {
  grid-column-gap: 4%;
  background-color: var(--pr-background-color);
  border-radius: 3ch;
  grid-template: ". longevity . . . ."
                 ". descript descript thumbnails . ."
                 / [pr-gauche] 5%[pr-tiers-gauche] 15%[pr-principale] 20%[pr-centrale] 20%[pr-contenu-fin] 15%[pr-tiers-droit] 5%[pr-finale];
  margin-bottom: 12ch;
  padding: 6em 0;
  display: grid;
  overflow: hidden;
  transform: skew(0deg, -4deg);
}

folio-experience::part(contents) {
  color: var(--pr-typeset-color);
  flex-direction: column;
  grid-area: descript;
  font-size: 1.5rem;
  display: flex;
}

folio-experience::part(preview) {
  flex-direction: row;
  grid-area: thumbnails;
  align-items: center;
  display: flex;
}

folio-experience::part(contents), folio-experience::part(preview) {
  transform: skew(0deg, 4deg);
}

folio-experience::part(timespan) {
  text-transform: uppercase;
  background-color: var(--pr-typeset-color);
  color: var(--pr-background-color);
  border-radius: 1em;
  align-self: start;
  margin-bottom: 2ch;
  padding: 1ch;
  font-family: Gilroy SemiBold, Avenir, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
}

.phone, .desktop, .desktopB {
  box-shadow: 0 1ch 2ch #00000080;
}

.thumbnail {
  border-radius: 16px;
}

.thumbnail.phone {
  width: 285px;
  height: 525px;
}

.thumbnail.desktop {
  width: 588.8px;
  height: 422.4px;
}

.thumbnail.desktopB {
  width: 592px;
  height: 425.6px;
}

.thumbnail.phone.rad32 {
  border-radius: 32px;
}

.thumbnail.layer-A {
  z-index: 5;
}

.thumbnail.layer-B {
  z-index: 4;
}

.thumbnail.layer-C {
  z-index: 3;
}

.thumbnail.layer-D {
  z-index: 2;
}

.thumbnail.layer-recess {
  z-index: -1;
}

.tilt-left-S {
  transform: rotate(-5deg);
}

.tilt-right-S {
  transform: rotate(5deg);
}

.tilt-left-XS {
  transform: rotate(-2deg);
}

.tilt-right-XS {
  transform: rotate(2deg);
}

.shift-left-S, .shift-right-S {
  transform: translateX(-4ch);
}

.shift-left-M, .shift-right-M {
  transform: translateX(-12ch);
}

.shift-left-and-tilt {
  transform: translateX(-6ch)rotate(-5deg);
}

time[slot="timespan-from"] {
  margin-right: 1ch;
}

time[slot="timespan-to"] {
  margin-left: 1ch;
}

.intro {
  font-size: 1.5rem;
}

li.intro {
  line-height: 2.2rem;
}

h3.intro {
  margin-top: 0;
}

.strong-kpi, cite {
  font-family: Gilroy Heavy, Avenir Black, Arial, sans-serif;
  font-style: normal;
  font-weight: 900;
}

.release {
  text-transform: uppercase;
  background-color: var(--pr-background-color);
  color: var(--pr-typeset-color);
  border-radius: 1em;
  align-self: start;
  margin-bottom: 2ch;
  padding: 1ch;
  font-family: Gilroy SemiBold, Avenir, Arial, sans-serif;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1rem;
  display: inline-block;
}

.pub-talks .release {
  margin: 0;
}

.pub-talks-peryear {
  flex-direction: column;
}

.pub-talks-peryear + .pub-talks-peryear {
  margin-top: 2ch;
}

.is-sncf-connect {
  --pr-background-color: #127996;
  --pr-typeset-color: #fff;
}

.is-assistant-sncf {
  --pr-background-color: #e0f3fd;
  --pr-typeset-color: #303ea6;
}

.is-renault {
  --pr-background-color: #ffce00;
  --pr-typeset-color: #000;
}

.is-poscat {
  --pr-background-color: #ffd861;
  --pr-typeset-color: #333;
}

.is-mecaniques {
  --pr-background-color: #df1f36;
  --pr-typeset-color: #fff;
}

.pub-books {
  grid-area: books;
}

.pub-talks {
  grid-area: talks;
}

.as-flex {
  align-items: flex-start;
  display: flex;
}

.as-flex.book, .as-flex.pub {
  flex-direction: column;
}

.is-book {
  height: 300px;
}

.is-talk {
  margin-bottom: 2ch;
  font-family: sans-serif;
  font-size: 1rem;
  font-weight: 400;
  display: inline-block;
}

@media (width > 1200px) {
  .as-social-networks {
    margin-left: 12ch;
  }

  .masthead-about-statement {
    max-width: 50ch;
  }

  .masthead-nav {
    margin-top: 4ch;
    margin-bottom: 12ch;
  }
}

@media (width <= 1200px) {
  body {
    grid-template-areas: ". masthead masthead masthead masthead ."
                         "portfolio portfolio portfolio portfolio portfolio portfolio"
                         ". publications publications publications publications ."
                         "footer footer footer footer footer footer";
  }

  .masthead-nav {
    flex-wrap: wrap;
    justify-content: center;
  }

  .as-social-networks {
    margin-left: 0;
  }

  .is-nav {
    margin-top: 2ch;
  }
}

@media (width > 800px) {
  li.nav-list-item + li.nav-list-item {
    margin-left: 4ch;
  }
}

@media (width <= 800px) {
  :root {
    padding-top: 12ch;
    font-size: 11px;
  }

  body, .masthead, #publications {
    grid-column-gap: 5ch;
  }

  body {
    grid-template-columns: [A] 5ch[B] 1fr[C] 2fr[D] 1fr[E] 5ch[F];
    grid-template-areas: ". masthead masthead masthead ."
                         "portfolio portfolio portfolio portfolio portfolio"
                         "publications publications publications publications publications";
    margin: 0;
    padding: 0;
  }

  #publications {
    grid-area: publications;
    grid-template-columns: [A] 5ch[B] 1fr[C] 1fr[D] 5ch[F];
    grid-template-areas: ". books books ."
                         ". talks talks .";
  }

  .masthead {
    grid-template-columns: 1fr;
    grid-template-areas: "about"
                         "navigation";
  }

  .masthead-grapheme {
    transform: translate(-35%, -65%)scale(.8);
  }

  .masthead-nav {
    flex-direction: column;
    gap: 2ch;
  }

  body, .masthead, folio-experience::part(container) {
    grid-column-gap: 0;
  }

  .nav-list {
    flex-wrap: wrap;
    gap: 4ch;
  }

  .as-social-networks {
    margin-left: 0;
  }

  .nav-list-link {
    padding: 1ch;
  }

  .icon-social {
    width: 2.6em;
  }

  .masthead-about-statement:last-child {
    margin-bottom: 4ch;
  }

  .is-nav + .is-nav {
    margin-top: 2ch;
  }

  .portfolio {
    margin-top: 4ch;
  }

  folio-experience::part(container) {
    grid-template-columns: [A] 5ch[B] 1fr[C] 2fr[D] 1fr[E] 5ch[F];
    grid-template-areas: ". longevity . . ."
                         ". descript descript descript ."
                         ". thumbnails thumbnails thumbnails thumbnails";
    padding: 12ch 0;
  }

  folio-experience::part(contents) {
    margin-bottom: 4ch;
  }

  .list-kpi-item {
    margin-bottom: 1ch;
  }

  .thumbnail.phone {
    width: 213.75px;
    height: 393.75px;
  }

  strong.strong-kpi {
    font-family: Gilroy SemiBold, Avenir Medium, Arial, sans-serif;
    font-weight: 500;
  }

  .pub-talks {
    margin-top: 4ch;
  }
}
/*# sourceMappingURL=index.3ca2a711.css.map */
